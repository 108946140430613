import React from 'react';

const HtmlRender = ({children, className, dom="div"}) => {
    return dom === "p" 
    ? (
        <p className={className} dangerouslySetInnerHTML={{__html: children}}>
            
        </p>
    )
    : (
        <div className={className} dangerouslySetInnerHTML={{__html: children}}>
            
        </div>
    )
};

export default HtmlRender;