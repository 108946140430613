import React from "react";
import ContainerFluid from "./ContainerFluid";
import styles from "./Footer.module.scss";
import { useRouter } from "next/router";
import homeContent from "../i18n/home";
import Link from "next/link";
import smoothScroll from "../common/smoothscroll";
import HtmlRender from "../component/HtmlRender";
import classNames from "classnames";

const Footer = ({ className }) => {
  const { locale } = useRouter();
  return (
    <ContainerFluid className="home-contact">
      <div className={classNames(styles.footer, className)}>
        <div className="container px-4">
          {/* {homeContent.contact[locale]} */}
          <div className="row mx-n4">
            <div className="col-12 col-lg-4 px-4">
              <h5 className="text-uppercase mb-3">
                {homeContent.footer.left.title[locale]}
              </h5>
              <ul className="ml-3" type="disc">
                <li>
                  <Link href="/contact">
                    {homeContent.footer.left.email[locale]}
                  </Link>
                </li>
                <li>
                  <a
                    href={`tel: ${process.env.NEXT_PUBLIC_HOTLINE}`}
                    className="cursor-pointer"
                  >
                    {homeContent.footer.left.hotline[locale]}
                  </a>
                </li>
                <li>
                  <HtmlRender>
                    {homeContent.footer.left.address[locale]}
                  </HtmlRender>
                </li>
              </ul>
              <div className="d-flex justify-content-start justify-content-lg-center align-items-center">
                <Link href="https://www.facebook.com/estuaryvietnam" passHref>
                  <a
                    style={{ marginTop: "20px", marginRight: "10px" }}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      height="40px"
                      src="/static/fb.png"
                      alt="Estuary solutions facebook"
                    />
                  </a>
                </Link>
                <Link
                  href="https://www.linkedin.com/company/estuary-solutions"
                  passHref
                >
                  <a rel="noopener noreferrer" target="_blank">
                    <img
                      src="/static/linkedin.png"
                      alt="Estuary solutions linkedin"
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-12 col-lg-4 px-4">
              <h5 className="text-uppercase mb-3 mt-3 mt-lg-0">
                {homeContent.footer.center.title[locale]}
              </h5>
              <ul className="ml-3" type="disc">
                <li>
                  <Link href={`/services/${homeContent.service.list[0].slug}`}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: homeContent.service.list[0].title_text[locale],
                      }}
                    />
                  </Link>
                </li>
                <li>
                  <Link href={`/services/${homeContent.service.list[1].slug}`}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: homeContent.service.list[1].title_text[locale],
                      }}
                    />
                  </Link>
                </li>
                <li>
                  <Link href={`/services/${homeContent.service.list[2].slug}`}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: homeContent.service.list[2].title_text[locale],
                      }}
                    />
                  </Link>
                </li>
                <li>
                  <Link href={`/services/${homeContent.service.list[3].slug}`}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: homeContent.service.list[3].title_text[locale],
                      }}
                    />
                  </Link>
                </li>
                <li>
                  <Link href={`/services/${homeContent.service.list[4].slug}`}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: homeContent.service.list[4].title_text[locale],
                      }}
                    />
                  </Link>
                </li>
                <li>
                  <Link href={`/services/${homeContent.service.list[5].slug}`}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: homeContent.service.list[5].title_text[locale],
                      }}
                    />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-4 px-4">
              <h5 className="text-uppercase mb-3 mt-3 mt-lg-0">
                {homeContent.footer.right.title[locale]}
              </h5>
              <ul className="ml-3" type="disc">
                {/* <li style={{cursor: "pointer"}} onClick={() => smoothScroll.scrollTo("home-banner", undefined, 70)}>{homeContent.footer.right.home[locale]}</li> */}
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    smoothScroll.scrollTo("home-banner", undefined, 60)
                  }
                >
                  {homeContent.footer.right.introduction[locale]}
                </li>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    smoothScroll.scrollTo("home-news", undefined, 60)
                  }
                >
                  {homeContent.footer.right.news[locale]}
                </li>
              </ul>
              <p className="mb-0 mt-4">© 2022 Estuary Solutions</p>
              <div className="d-flex flex-column justify-content-start align-items-start">
                <img
                  src="/static/iso27001.png"
                  alt="Estuary solutions ISO27001"
                />
                <img
                  src="/static/iso9001.png"
                  alt="Estuary solutions ISO9001"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerFluid>
  );
};

export default Footer;
